import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumbport from '../assets/images/thumbs/port.jpg'
import thumbcatch from '../assets/images/thumbs/catch.jpg'
import thumbface from '../assets/images/thumbs/face.jpg'
import thumbhiragana from '../assets/images/thumbs/hiragana.jpg'
import thumbrent from '../assets/images/thumbs/rent.jpg'
import thumbblog from '../assets/images/thumbs/blog.jpg'

import fullport from '../assets/images/fulls/port.jpg'
import fullcatch from '../assets/images/fulls/catch.jpg'
import fullface from '../assets/images/fulls/face.jpg'
import fullhiragana from '../assets/images/fulls/hiragana.jpg'
import fullrent from '../assets/images/fulls/rent.jpg'
import fullblog from '../assets/images/fulls/blog.jpg'

const DEFAULT_IMAGES = [
  {
    id: '1',
    source: fullport,
    thumbnail: thumbport,
    caption: 'Personalised React portfolio site',
    description: 'React, vanialla CSS',
  },
  {
    id: '2',
    source: fullcatch,
    thumbnail: thumbcatch,
    caption: 'Restaurant menu with ordering and inventory',
    description: `firebase, OAuth, React`,
  },
  {
    id: '3',
    source: fullface,
    thumbnail: thumbface,
    caption: 'Face detection tool - paste an img URL in the box',
    description: 'clarifAI, React, Tachyons, Node, Express',
  },
  {
    id: '4',
    source: fullhiragana,
    thumbnail: thumbhiragana,
    caption: 'My first JS project - Hiragana flash cards',
    description: 'Vanilla JS, CSS',
  },
  {
    id: '5',
    source: fullrent,
    thumbnail: thumbrent,
    caption: 'Full stack functional marketplace to rent and lend things',
    description: 'RoR, Heroku, Postgres',
  },
  {
    id: '6',
    source: fullblog,
    thumbnail: thumbblog,
    caption: 'Personalised blog site',
    description: 'Gatsby, React, Styled Components',
  },
]

class HomeIndex extends React.Component {
  render() {
    const siteTitle = 'Joel James | Full Stack Developer'
    const siteDescription = 'Full stack developer in Melbourne'

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="description" content={siteDescription} />
          <meta property="og:title" content={siteTitle} />
          <meta property="og:image" content="%PUBLIC_URL%/og-image.jpg" />.
          <meta
            property="og:description"
            content="Portfolio, About and Contact - Joel James"
          />
          <meta property="og:url" content="https://jayfiled.com" />
          <meta property="og:type" content="website" />
        </Helmet>

        <div id="main">
          <section id="one">
            <header className="major">
              <h2>Full stack developer working with any technology</h2>
            </header>
            <p>
              I work with .Net and SQL, I've supported and maintained business
              critical apps over 8 years in the industry. I spend all my spare time on personal projects.
              Currently enjoying React, Node, GraphQL, Apollo and
              YogaJS.
            </p>
            <ul className="actions">
              <li>
                <a
                  href="https://drive.google.com/file/d/1SU1_alwHhUDSWpl6B-JtboWKRpJcVZSh/view?usp=sharing"
                  className="button"
                >
                  Learn More
                </a>
              </li>
            </ul>
          </section>

          <section id="two">
            <h2>Recent Work</h2>

            <Gallery
              images={DEFAULT_IMAGES.map(
                ({ id, source, thumbnail, caption, description }) => ({
                  source,
                  thumbnail,
                  caption,
                  description,
                })
              )}
            />

            <ul className="actions">
              <li>
                <a href="https://github.com/jayfiled" className="button">
                  Full Portfolio
                </a>
              </li>
            </ul>
          </section>

          <section id="three">
            <h2>Get In Touch</h2>
            <p>
              Let's collaborate.
            </p>
            <div className="row">
              <div className="4u 12u$(small)">
                <ul className="labeled-icons">
                  <li>
                    <h3 className="icon fa-home">
                      <span className="label">Address</span>
                    </h3>
                    Melbourne, VIC 3194
                    <br />
                    Australia
                  </li>
                  <li>
                    <h3 className="icon fa-mobile">
                      <span className="label">Phone</span>
                    </h3>
                    Get in touch via Linked in or email
                  </li>
                  <li>
                    <h3 className="icon fa-envelope-o">
                      <span className="label">Email</span>
                    </h3>
                    <a href="mailto:hello@jayfiled.com">hello@jayfiled.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default HomeIndex
